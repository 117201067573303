import { useSearchParams } from "next/navigation";

import { useFormV2Context } from "@/features/FormV2/context/FormV2Context";
import { FormType } from "@/gql-types";

export const useShouldHideFundraise = () => {
  const { formObject } = useFormV2Context(FormType.DonationForm);
  const searchParams = useSearchParams();

  return formObject.hideFundraiseButton || searchParams?.get("noFundraise")?.toLowerCase() === "true";
};
